<template>
  <b-card v-if="datasource.loaded">
    <template #header>
      <h4>
        <span v-if="datasource.customer.customer_type == 'company'">Firma</span>
        <span v-if="datasource.customer.customer_type == 'member'">Kişi</span>
        Kartı Bilgileri - {{ datasource.customer.customer_code }}
      </h4>
    </template>
    <b-form>
      <b-row>
        <b-col col lg="6" sm="12">
          <h5 class="">Müşteri Bilgileri</h5>
          <div class="cardbody mt-1">
            <b-row>
              <b-col lg="12" sm="12">
                <b-form-group label="Müşteri Kodu">
                  <b-form-input
                    v-model="datasource.customer.customer_code"
                    class="bg-disabled"
                    placeholder="6 haneli müşteri kodu"
                    autocomplate="off"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="12" sm="12">
                <b-form-group
                  v-if="datasource.customer.customer_type == 'company'"
                  label="Bağlı Olduğu Firma"
                >
                  <vue-autosuggest v-if="false" />
                </b-form-group>
                <b-form-group
                  v-if="datasource.customer.customer_type == 'member'"
                  label="Ad Soyad"
                >
                  <b-form-input
                    v-model="datasource.customer.title"
                    placeholder="ad ve soyadı lütfen tutarlı girin"
                    autocomplate="off"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="datasource.customer.customer_type == 'company'"
                  label="Firma Ünvanı"
                >
                  <b-form-input
                    v-model="datasource.customer.title"
                    placeholder=""
                    autocomplate="off"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group label="GSM">
                  <b-form-input
                    type="number"
                    v-model="datasource.customer.gsm_primary"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group label="Email">
                  <b-form-input
                    v-model="datasource.customer.email_primary"
                    type="email"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col
                v-if="datasource.customer.customer_type == 'member'"
                lg="6"
                sm="12"
              >
                <b-form-group label="Doğum Tarihi">
                  <flat-pickr
                    v-model="datasource.customer.dateofbirth"
                    class="form-control"
                    :config="{ dateFormat: 'd.m.Y', locale: 'tr' }"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="datasource.customer.customer_type == 'member'"
                lg="6"
                sm="12"
              >
                <b-form-group class="mt-1">
                  <label></label>
                  <b-form-checkbox
                    v-model="datasource.customer.sms_dateofbirth"
                    value="1"
                    class="custom-control-primary"
                  >
                    Doğum gününde kutlama mesajı gönder
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col col lg="6" sm="12">
          <h5 class="">Cari Bilgileri</h5>
          <div class="cardbody mt-1">
            <b-row>
              <b-col
                v-if="datasource.customer.customer_type == 'member'"
                lg="6"
                sm="12"
              >
                <b-form-group label="TCKN">
                  <b-form-input
                    placeholder="fatura için gereklidir"
                    v-model="datasource.customer.invoice_tax_number"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col
                v-if="datasource.customer.customer_type == 'company'"
                lg="6"
                sm="12"
              >
                <b-form-group label="Vergi No">
                  <b-form-input
                    v-model="datasource.customer.invoice_tax_number"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col
                v-if="datasource.customer.customer_type == 'company'"
                lg="6"
                sm="12"
              >
                <b-form-group label="Vergi Dairesi">
                  <b-form-input
                    v-model="datasource.customer.invoice_tax_office"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col col col-6>
                <b-form-group label="Posta Kodu">
                  <b-form-input
                    v-model="datasource.customer.invoice_zipcode"
                    placeholder=""
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group label="İl">
                  <v-select
                    :searchable="true"
                    :clearable="true"
                    v-model="datasource.customer.city_id"
                    :options="global.cities"
                    @input="getDistricts()"
                    :reduce="(curr) => curr.value"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group label="İlçe">
                  <v-select
                    :searchable="true"
                    :clearable="true"
                    v-model="datasource.customer.district_id"
                    :options="datasource.districts"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="12" sm="12">
                <b-form-group label="Açık Adres">
                  <b-form-textarea
                    v-model="datasource.customer.invoice_address"
                    placeholder="açık adres"
                    rows="4"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group
                  v-if="datasource.customer.customer_type == 'company'"
                >
                  <label></label>
                  <b-form-checkbox
                    v-model="datasource.customer.is_supplier"
                    value="1"
                    class="custom-control-primary"
                  >
                    Tedarkçi
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-card-footer class="p-0 pt-2 ml-n2 mr-n2 mt-2 pl-2 pr-2">
        <div class="d-flex justify-content-between">
          <button v-on:click="save()" class="btn btn-primary" type="button">
            Kaydet
          </button>
          <button
            v-on:click="confirmDelete()"
            class="btn btn-danger"
            type="button"
          >
            Sil
          </button>
        </div>
      </b-card-footer>
    </b-form>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueAutosuggest } from "vue-autosuggest";
import { tr } from "flatpickr/dist/l10n/tr.js";
import api from "@/service/api";
import utils from "@/service/utils";
import _ from "lodash";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: {
    vSelect,
    flatPickr,
    VueAutosuggest,
  },
  data() {
    return {
      datasource: {
        loaded: false,
        customer: {
          customer_id: "",
          office_id: "",
          company_id: "",
          customer_uniq: "",
          customer_code: "",
          customer_type: "",
          title: "",
          gsm_primary: "",
          gsm_secondary: null,
          email_primary: "",
          email_secondary: null,
          invoice_address: "",
          dateofbirth: "",
          invoice_city_id: "",
          invoice_district_id: "",
          invoice_tax_number: "",
          invoice_tax_office: "",
          invoice_zipcode: "",
          sms_dateofbirth: "",
          created_props: null,
          updated_props: null,
        },
        cities: [],
        districts: [],
      },
    };
  },
  created() {
    if (!utils.userHasRole("update_customer")) {
      this.$router.push("/not-found");
      return;
    }
    utils.setTitle("Müşteri Bilgileri");
    this.getCustomer();
    utils.getCities().forEach((q) => {
      q.value = q.city_id;
      q.label = "[" + q.city_id + "] " + q.city_name;
      this.datasource.cities.push(q);
    });
  },
  computed: {
    ...mapState({
      global: (state) => state.global,
    }),
  },
  methods: {
    getCustomer() {
      let customer_uniq = this.$route.query.customer;
      api.action("getCustomer", { customer_uniq: customer_uniq }).then((q) => {
        if (q.success == false) {
          this.$router.push("/not-found");
          return;
        }
        this.datasource.customer = q.data.customer;
        let c = q.data.customer;

        let city = this.datasource.cities.find(
          (qq) => qq.city_id == c.invoice_city_id
        );
        if (city != null) {
          this.datasource.customer.city_id = city;
          this.getDistricts();
          let district = this.datasource.districts.find(
            (qq) => qq.district_id == c.invoice_district_id
          );
          if (district != null) {
            this.datasource.customer.district_id = district;
          }
        }
        this.datasource.loaded = true;
        let breadcrumb = this.$route.meta.breadcrumb;
        breadcrumb[1].text = this.datasource.customer.title;
        this.$route.meta.breadcrumb = breadcrumb;
      });
    },
    save() {
      let postData = {};
      Object.entries(this.datasource.customer).forEach((q) => {
        let key = q[0];
        let val = _.isObject(q[1]) ? q[1].value : q[1];
        postData[key] = val;
      });

      postData.sms_dateofbirth = postData.sms_dateofbirth == null ? 0 : 1;
      postData.is_supplier = postData.is_supplier == null ? 0 : 1;

      if (
        postData.customer_code.length < 6 ||
        postData.customer_code.length >= 12
      ) {
        utils.notificationToast(
          "warning",
          "Bilgi",
          "Lütfen geçerli bir müşteri kodu yazın."
        );
        return;
      }
      if (postData.customer_type == "member") {
        if (postData.title.length <= 3) {
          utils.notificationToast(
            "warning",
            "Bilgi",
            "Lütfen ad ve soyadı yazın."
          );
          return;
        }
        if (postData.gsm_primary.length <= 9) {
          utils.notificationToast(
            "warning",
            "Bilgi",
            "Lütfen gsm numarası yazın."
          );
          return;
        }
        if (postData.city_id <= 0) {
          utils.notificationToast(
            "warning",
            "Bilgi",
            "Lütfen müşterinin bulunduğu şehiri seçin."
          );
          return;
        }
      }
      if (postData.customer_type == "company") {
        if (postData.title.length <= 3) {
          utils.notificationToast(
            "warning",
            "Bilgi",
            "Lütfen firmanın adını yazın."
          );
          return;
        }
        if (postData.city_id <= 0) {
          utils.notificationToast(
            "warning",
            "Bilgi",
            "Lütfen firmanın bulunduğu şehiri seçin."
          );
          return;
        }
      }
      api.action("updateCustomer", postData).then((q) => {
        if (q.success == false) {
          utils.notificationToast("warning", "Bilgi", q.message);
          return;
        }
        utils.notificationToast("success", "Bilgi", q.message);
      });
    },
    getDistricts() {
      this.datasource.districts = [];
      this.datasource.customer.district_id = 0;
      if (
        this.datasource.customer.city_id == null ||
        this.datasource.customer.city_id.value == null
      )
        return;
      utils
        .getDistricts(this.datasource.customer.city_id.value)
        .forEach((q) => {
          q.value = q.district_id;
          q.label = q.district_name;
          this.datasource.districts.push(q);
        });
    },
    confirmDelete() {
      this.$swal({
        title: "Müşteriyi Sil",
        text:
          this.datasource.customer.title +
          " isimli müşterinizi silmek istediğinizden emin misiniz?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Onayla",
        cancelButtonText: "İptal",
        customClass: { ...utils.defaultSweetStyle },
        buttonsStyling: false,
      }).then((q) => {
        if (q.isConfirmed) {
          api
            .action("deleteCustomer", {
              customer_uniq: this.datasource.customer.customer_uniq,
            })
            .then((q) => {
              if (q.success) {
                this.toast("success", "Bilgi", q.message);
                this.$router.push({ name: "customer-list" });
              } else {
                this.toast("warning", "Bilgi", q.message);
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>